<template>
  <ion-page>
    <ion-content>
      <div class="container" id="container">
        <div data-custom-class="body">
          <div align="center" style="text-align: left;">
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                align="center"
                class="MsoNormal"
                style="text-align:center;line-height:150%;"
              >
                <a name="_2cipo4yr3w5d" id="_2cipo4yr3w5d"></a>
                <div
                  align="center"
                  class="MsoNormal"
                  style="line-height: 22.5px;"
                >
                  <div
                    align="center"
                    class="MsoNormal"
                    style="line-height: 150%;"
                  >
                    <a name="_gm5sejt4p02f" id="_gm5sejt4p02f"></a>
                    <div
                      align="center"
                      class="MsoNormal"
                      data-custom-class="title"
                      style="text-align: left; line-height: 1.5;"
                    >
                      <strong
                        ><span style="line-height: 22.5px; font-size: 26px;"
                          >END USER LICENSE AGREEMENT</span
                        ></strong
                      >
                    </div>
                    <div
                      align="center"
                      class="MsoNormal"
                      style="line-height: 22.5px; text-align: left;"
                    >
                      <a name="_7m5b3xg56u7y" id="_7m5b3xg56u7y"></a>
                    </div>
                  </div>
                </div>
                <div
                  align="center"
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left;"
                >
                  <br />
                </div>
                <div
                  align="center"
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left;"
                >
                  <span style="font-size: 11pt; line-height: 16.8667px;"
                    ><strong
                      ><span data-custom-class="body_text"
                        ><span style="color: rgb(89, 89, 89);"
                          >Last updated</span
                        >&nbsp;</span
                      ></strong
                    ><strong
                      ><span data-custom-class="body_text"
                        >December 13, 2023</span
                      ></strong
                    ></span
                  >
                </div>
                <div
                  align="center"
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left;"
                >
                  <br />
                </div>
                <div
                  align="center"
                  class="MsoNormal"
                  style="line-height: 17.25px; text-align: left;"
                >
                  <span style="font-size: 11pt; line-height: 16.8667px;"
                    ><br
                  /></span>
                </div>
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                style="line-height: 115%;"
              >
                <a name="_a7mwfgcrtsqn" id="_a7mwfgcrtsqn"></a>
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  >Starlink - Cast Connect is licensed to You (End-User) by
                  Ozunu Corp, located and registered at 7105 Old Katy Rd Apt
                  4131,
                  <span
                    style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                    >Houston, Texas 77024<span style="font-size: 15px;"
                      >, United States&nbsp;</span
                    ></span
                  >("<strong>Licensor</strong>"), for use only under the terms
                  of this License Agreement.</span
                >
                <span
                  style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"
                  ><span
                    style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                    ><span
                      style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                      ><span
                        style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                        ><span
                          style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                          ><span
                            style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                          ></span></span></span></span></span
                ></span>
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  >By downloading the Licensed Application from Apple's software
                  distribution platform ("App Store") and Google's software
                  distribution platform ("Play Store"), and any update thereto
                  (as permitted by this License Agreement), You indicate that
                  You agree to be bound by all of the terms and conditions of
                  this License Agreement, and that You accept this License
                  Agreement. App Store and Play Store are referred to in this
                  License Agreement as "<strong>Services</strong>."</span
                >
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  >The parties of this License Agreement acknowledge that the
                  Services are not a Party to this License Agreement and are not
                  bound by any provisions or obligations with regard to the
                  Licensed Application, such as warranty, liability, maintenance
                  and support thereof. Ozunu Corp, not the Services, is solely
                  responsible for the Licensed Application and the content
                  thereof.</span
                >
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >This License Agreement may not provide for usage rules for
                    the Licensed Application that are in conflict with the
                    latest</span
                  ></span
                >
                <a
                  data-custom-class="link"
                  href="https://www.apple.com/legal/internet-services/itunes/us/terms.html"
                  rel="noopener noreferrer"
                  target="_blank"
                  ><span style="font-size: 15px;"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >Apple Media Services Terms and Conditions</span
                    ></span
                  ></a
                >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >and</span
                  ></span
                >
                <a
                  data-custom-class="link"
                  href="https://play.google.com/intl/en_US/about/play-terms/"
                  rel="noopener noreferrer"
                  target="_blank"
                  ><span style="font-size: 15px;"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >Google Play Terms of Service</span
                    ></span
                  ></a
                >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >("<strong>Usage Rules</strong>").</span
                  ></span
                >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >Ozunu Corp acknowledges that it had the opportunity to
                    review the Usage Rules and this License Agreement is not
                    conflicting with them.</span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                Starlink - Cast Connect
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >when purchased or downloaded through the Services, is
                    licensed to You for use only under the terms of this License
                    Agreement. The Licensor reserves all rights not expressly
                    granted to You. Starlink - Cast Connect is to be used on
                    devices that operate with Apple's operating systems ("iOS"
                    and "Mac OS") or Google's operating system
                    ("Android").</span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                style="line-height: 1.5;"
              >
                <strong
                  ><span style="font-size: 19px;"
                    >TABLE OF CONTENTS</span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('application')"
                  >1. THE APPLICATION</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('scope')"
                  >2. SCOPE OF LICENSE</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('requirements')"
                  >3. TECHNICAL REQUIREMENTS</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('support')"
                  >4. MAINTENANCE AND SUPPORT</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('datause')"
                  >5. USE OF DATA</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('ugc')"
                  >6. USER-GENERATED CONTRIBUTIONS</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('contribution')"
                  >7. CONTRIBUTION LICENSE</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('liability')"
                  >8. LIABILITY</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('warranty')"
                  >9. WARRANTY</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('productclaims')"
                  >10. PRODUCT CLAIMS</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('compliance')"
                  >11. LEGAL COMPLIANCE</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('contact')"
                  >12. CONTACT INFORMATION</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('termination')"
                  >13. TERMINATION</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('thirdparty')"
                  >14. THIRD-PARTY TERMS OF AGREEMENTS AND BENEFICIARY</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('ipr')"
                  >15. INTELLECTUAL PROPERTY RIGHTS</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('law')"
                  >16. APPLICABLE LAW</a
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <a
                  data-custom-class="link"
                  href="javascript:void(0);"
                  @click="scrollToSection('misc')"
                  >17. MISCELLANEOUS</a
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="application"
                style="line-height: 1.5;"
              >
                <a name="_4rd71iod99ud" id="_4rd71iod99ud"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;"
                    ><span style="font-size: 19px;"
                      ><strong>1.</strong
                      ><strong>&nbsp;THE APPLICATION</strong></span
                    ></span
                  ></strong
                >
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%; Arial;mso-fareast-font-family:Calibri;color:#595959;mso-themecolor:text1; mso-themetint:166;"
                    >Starlink - Cast Connect</span
                  >
                  ("<strong>Licensed Application</strong>") is a piece of
                  software created to With the Starlink - Cast Connect app, you
                  can: - Create private or public rooms - Test your knowledge of
                  movies and the stars in them - Chat with other users within
                  the community individually or in groups with live updates and
                  notifications - Add and remove users from private
                  conversations - Schedule games to be played on a future date -
                  See your progress and how you rank against other players in
                  the community — and customized for iOS and Android mobile
                  devices ("<strong>Devices</strong>"). It is used to To connect
                  with your friends and family and test your knowledge of movies
                  and the stars in them..</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.1;"
              ></div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >The Licensed Application is not tailored to comply with
                  industry-specific regulations (Health Insurance Portability
                  and Accountability Act (HIPAA), Federal Information Security
                  Management Act (FISMA), etc.), so if your interactions would
                  be subjected to such laws, you may not use this Licensed
                  Application. You may not use the Licensed Application in a way
                  that would violate the Gramm-Leach-Bliley Act (GLBA).</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="scope"
                style="line-height: 1.5;"
              >
                <a name="_vhkegautf00d" id="_vhkegautf00d"></a
                ><strong
                  ><span
                    style="line-height: 115%; font-family: Arial; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><span style="font-size: 19px;"
                          ><strong>2. SCOPE OF LICENSE</strong></span
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 115%;"></div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        ><span style="color: rgb(89, 89, 89);"
                          >2.1 &nbsp;You are given a non-transferable,
                          non-exclusive, non-sublicensable license to install
                          and use the Licensed Application on any Devices that
                          You (End-User) own or control and as permitted by the
                          Usage Rules, with the exception that such Licensed
                          Application may be accessed and used by other accounts
                          associated with You (End-User, The Purchaser) via
                          Family Sharing or volume purchasing.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >2 &nbsp;This license will also govern any updates of
                          the Licensed Application provided by Licensor that
                          replace, repair, and/or supplement the first Licensed
                          Application, unless a separate license is provided for
                          such update, in which case the terms of that new
                          license will govern.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span
                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                    >2<span
                      style="color: rgb(89, 89, 89); font-size: 14.6667px;"
                      >.<span
                        style="color: rgb(89, 89, 89); font-size: 14.6667px;"
                        >3 &nbsp;You may not share or make the Licensed
                        Application available to third parties (unless to the
                        degree allowed by the Usage Rules, and with Ozunu Corp's
                        prior written consent), sell, rent, lend, lease or
                        otherwise redistribute the Licensed Application.</span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >4 &nbsp;You may not reverse engineer, translate,
                          disassemble, integrate, decompile, remove, modify,
                          combine, create derivative works or updates of, adapt,
                          or attempt to derive the source code of the Licensed
                          Application, or any part thereof (except with Ozunu
                          Corp's prior written consent).</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >5 &nbsp;You may not copy (excluding when expressly
                          authorized by this license and the Usage Rules) or
                          alter the Licensed Application or portions thereof.
                          You may create and store copies only on devices that
                          You own or control for backup keeping under the terms
                          of this license, the Usage Rules, and any other terms
                          and conditions that apply to the device or software
                          used. You may not remove any intellectual property
                          notices. You acknowledge that no unauthorized third
                          parties may gain access to these copies at any time.
                          If you sell your Devices to a third party, you must
                          remove the Licensed Application from the Devices
                          before doing so.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >6 &nbsp;Violations of the obligations mentioned
                          above, as well as the attempt of such infringement,
                          may be subject to prosecution and damages.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >7 &nbsp;Licensor reserves the right to modify the
                          terms and conditions of licensing.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1;"
              >
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      >2<span style="color: rgb(89, 89, 89);"
                        >.<span style="color: rgb(89, 89, 89);"
                          >8 &nbsp;Nothing in this license should be interpreted
                          to restrict third-party terms. When using the Licensed
                          Application, You must ensure that You comply with
                          applicable third-party terms and conditions.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 1.5;"
            >
              <br />
            </div>
            <div
              align="center"
              class="MsoNormal"
              style="text-align: left; line-height: 150%;"
            >
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="requirements"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong>3. TECHNICAL REQUIREMENTS</strong></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height:115%;"></div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        >3.<span style="color: rgb(89, 89, 89);"
                          >1 &nbsp;Licensor attempts to keep the Licensed
                          Application updated so that it complies with
                          modified/new versions of the firmware and new
                          hardware. You are not granted rights to claim such an
                          update.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        >3.<span style="color: rgb(89, 89, 89);"
                          >2 &nbsp;You acknowledge that it is Your
                          responsibility to confirm and determine that the app
                          end-user device on which You intend to use the
                          Licensed Application satisfies the technical
                          specifications mentioned above.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        >3.<span style="color: rgb(89, 89, 89);"
                          >3 &nbsp;Licensor reserves the right to modify the
                          technical specifications as it sees appropriate at any
                          time.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height:115%;"></div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="support"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong>4. MAINTENANCE AND SUPPORT</strong></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                  >4.1 &nbsp;The Licensor is solely responsible for providing
                  any maintenance and support services for this Licensed
                  Application. You can reach the Licensor at the email address
                  listed in the App Store or Play Store Overview for this
                  Licensed Application.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="line-height: 16.8667px;"
                  ><span style="line-height: 16.8667px;"
                    ><span style="line-height: 16.8667px;"
                      ><span style="line-height: 16.8667px;"
                        ><font color="#595959"
                          ><span style="font-size: 15px;">4.2&nbsp;</span></font
                        ><span style="font-size: 15px;"
                          >&nbsp;Ozunu Corp&nbsp;</span
                        ><font color="#595959"
                          ><span style="font-size: 15px;"
                            >and the End-User acknowledge that the Services have
                            no obligation whatsoever to furnish any maintenance
                            and support services with respect to the Licensed
                            Application.</span
                          ></font
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height:115%;"></div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="datause"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong>5. USE OF DATA</strong></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        ><span style="color: rgb(89, 89, 89);"
                          >You acknowledge that Licensor will be able to access
                          and adjust Your downloaded Licensed Application
                          content and Your personal information, and that
                          Licensor's use of such material and information is
                          subject to Your legal agreements with Licensor and
                          Licensor's privacy policy, which can be found at the
                          bottom of the Licensed Application.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >You acknowledge that the Licensor may periodically collect
                  and use technical data and related information about your
                  device, system, and application software, and peripherals,
                  offer product support, facilitate the software updates, and
                  for purposes of providing other services to you (if any)
                  related to the Licensed Application. Licensor may also use
                  this information to improve its products or to provide
                  services or technologies to you, as long as it is in a form
                  that does not personally identify you.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="ugc"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong
                                >6. USER-GENERATED CONTRIBUTIONS</strong
                              ></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        ><span style="color: rgb(89, 89, 89);"
                          >The Licensed Application may invite you to chat,
                          contribute to, or participate in blogs, message
                          boards, online forums, and other functionality, and
                          may provide you with the opportunity to create,
                          submit, post, display, transmit, perform, publish,
                          distribute, or broadcast content and materials to us
                          or in the Licensed Application, including but not
                          limited to text, writings, video, audio, photographs,
                          graphics, comments, suggestions, or personal
                          information or other material (collectively,
                          "Contributions"). Contributions may be viewable by
                          other users of the Licensed Application and through
                          third-party websites or applications. As such, any
                          Contributions you transmit may be treated as
                          non-confidential and non-proprietary. When you create
                          or make available any Contributions, you thereby
                          represent and warrant that:</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >1. The creation, distribution, transmission, public display,
                  or performance, and the accessing, downloading, or copying of
                  your Contributions do not and will not infringe the
                  proprietary rights, including but not limited to the
                  copyright, patent, trademark, trade secret, or moral rights of
                  any third party.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >2. You are the creator and owner of or have the necessary
                  licenses, rights, consents, releases, and permissions to use
                  and to authorize us, the Licensed Application, and other users
                  of the Licensed Application to use your Contributions in any
                  manner contemplated by the Licensed Application and this
                  License Agreement.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >3. You have the written consent, release, and/or permission
                  of each and every identifiable individual person in your
                  Contributions to use the name or likeness or each and every
                  such identifiable individual person to enable inclusion and
                  use of your Contributions in any manner contemplated by the
                  Licensed Application and this License Agreement.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >4. Your Contributions are not false, inaccurate, or
                  misleading.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >5. Your Contributions are not unsolicited or unauthorized
                  advertising, promotional materials, pyramid schemes, chain
                  letters, spam, mass mailings, or other forms of
                  solicitation.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >6. Your Contributions are not obscene, lewd, lascivious,
                  filthy, violent, harassing, libelous, slanderous, or otherwise
                  objectionable (as determined by us).</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >7. Your Contributions do not ridicule, mock, disparage,
                  intimidate, or abuse anyone.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >8. Your Contributions are not used to harass or threaten (in
                  the legal sense of those terms) any other person and to
                  promote violence against a specific person or class of
                  people.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >9. Your Contributions do not violate any applicable law,
                  regulation, or rule.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >10. Your Contributions do not violate the privacy or
                  publicity rights of any third party.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >11. Your Contributions do not violate any applicable law
                  concerning child pornography, or otherwise intended to protect
                  the health or well-being of minors.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >12. Your Contributions do not include any offensive comments
                  that are connected to race, national origin, gender, sexual
                  preference, or physical handicap.</span
                >
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5; margin-left: 20px;"
              >
                <span style="font-size: 15px;"
                  >13. Your Contributions do not otherwise violate, or link to
                  material that violates, any provision of this License
                  Agreement, or any applicable law or regulation.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1; margin-left: 20px;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >Any use of the Licensed Application in violation of the
                  foregoing violates this License Agreement and may result in,
                  among other things, termination or suspension of your rights
                  to use the Licensed Application.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="contribution"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong>7. CONTRIBUTION LICENSE</strong></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;"></div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >By posting your Contributions to any part of the Licensed
                  Application or making Contributions accessible to the Licensed
                  Application by linking your account from the Licensed
                  Application to any of your social networking accounts, you
                  automatically grant, and you represent and warrant that you
                  have the right to grant, to us an unrestricted, unlimited,
                  irrevocable, perpetual, non-exclusive, transferable,
                  royalty-free, fully-paid, worldwide right, and license to
                  host, use copy, reproduce, disclose, sell, resell, publish,
                  broad cast, retitle, archive, store, cache, publicly display,
                  reformat, translate, transmit, excerpt (in whole or in part),
                  and distribute such Contributions (including, without
                  limitation, your image and voice) for any purpose, commercial
                  advertising, or otherwise, and to prepare derivative works of,
                  or incorporate in other works, such as Contributions, and
                  grant and authorize sublicenses of the foregoing. The use and
                  distribution may occur in any media formats and through any
                  media channels.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >This license will apply to any form, media, or technology now
                  known or hereafter developed, and includes our use of your
                  name, company name, and franchise name, as applicable, and any
                  of the trademarks, service marks, trade names, logos, and
                  personal and commercial images you provide. You waive all
                  moral rights in your Contributions, and you warrant that moral
                  rights have not otherwise been asserted in your
                  Contributions.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >We do not assert any ownership over your Contributions. You
                  retain full ownership of all of your Contributions and any
                  intellectual property rights or other proprietary rights
                  associated with your Contributions. We are not liable for any
                  statements or representations in your Contributions provided
                  by you in any area in the Licensed Application. You are solely
                  responsible for your Contributions to the Licensed Application
                  and you expressly agree to exonerate us from any and all
                  responsibility and to refrain from any legal action against us
                  regarding your Contributions.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  >We have the right, in our sole and absolute discretion, (1)
                  to edit, redact, or otherwise change any Contributions; (2) to
                  recategorize any Contributions to place them in more
                  appropriate locations in the Licensed Application; and (3) to
                  prescreen or delete any Contributions at any time and for any
                  reason, without notice. We have no obligation to monitor your
                  Contributions.</span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <span style="font-size: 15px;"><br /></span>
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <span style="font-size: 15px;"><br /></span>
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="liability"
                style="line-height: 17.25px;"
              >
                <span style="font-size: 15px;"
                  ><strong
                    ><span style="line-height: 24.5333px; font-size: 19px;"
                      ><strong
                        ><span
                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                              ><span style="font-size: 19px;"
                                ><strong>8. LIABILITY</strong></span
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height:115%;"></div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        ><span style="color: rgb(89, 89, 89);"
                          >8.1 &nbsp;Licensor's responsibility in the case of
                          violation of obligations and tort shall be limited to
                          intent and gross negligence. Only in case of a breach
                          of essential contractual duties (cardinal
                          obligations), Licensor shall also be liable in case of
                          slight negligence. In any case, liability shall be
                          limited to the foreseeable, contractually typical
                          damages. The limitation mentioned above does not apply
                          to injuries to life, limb, or health.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span style="font-size: 15px;"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span
                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        ><span style="color: rgb(89, 89, 89);"
                          >8.2 &nbsp;Licensor takes no accountability or
                          responsibility for any damages caused due to a breach
                          of duties according to Section 2 of this License
                          Agreement. To avoid data loss, You are required to
                          make use of backup functions of the Licensed
                          Application to the extent allowed by applicable
                          third-party terms and conditions of use. You are aware
                          that in case of alterations or manipulations of the
                          Licensed Application, You will not have access to the
                          Licensed Application.</span
                        ></span
                      ></span
                    ></span
                  ></span
                >
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div class="MsoNormal" style="line-height: 1.5;">
                <br />
              </div>
              <div
                class="MsoNormal"
                data-custom-class="heading_1"
                id="warranty"
                style="line-height: 17.25px;"
              >
                <strong
                  ><span style="line-height: 24.5333px; font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><span style="font-size: 19px;"
                              ><strong>9. WARRANTY</strong></span
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></strong
                >
              </div>
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5;"
          >
            <span style="font-size: 15px;"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  >9.1 &nbsp;Licensor warrants that the Licensed Application is
                  free of spyware, trojan horses, viruses, or any other malware
                  at the time of Your download. Licensor warrants that the
                  Licensed Application works as described in the user
                  documentation.</span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1;">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5;"
          >
            <span style="font-size: 15px;"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span style="color: rgb(89, 89, 89);"
                    ><span style="color: rgb(89, 89, 89);"
                      >9.2 &nbsp;No warranty is provided for the Licensed
                      Application that is not executable on the device, that has
                      been unauthorizedly modified, handled inappropriately or
                      culpably, combined or installed with inappropriate
                      hardware or software, used with inappropriate accessories,
                      regardless if by Yourself or by third parties, or if there
                      are any other reasons outside of Ozunu Corp's sphere of
                      influence that affect the executability of the Licensed
                      Application.</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1;">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5;"
          >
            <span style="font-size: 15px;"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span style="color: rgb(89, 89, 89);"
                    ><span style="color: rgb(89, 89, 89);"
                      >9.3 &nbsp;You are required to inspect the Licensed
                      Application immediately after installing it and notify
                      Ozunu Corp about issues discovered without delay by email
                      provided in</span
                    ></span
                  ></span
                ></span
              >&nbsp;</span
            ><a data-custom-class="link" href="#contact"
              ><span style="font-size: 15px;"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                    ><span style="color: rgb(89, 89, 89);"
                      ><span style="color: rgb(89, 89, 89);"
                        >Contact Information</span
                      ></span
                    ></span
                  ></span
                ></span
              ></a
            ><span style="font-size: 15px;"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span style="color: rgb(89, 89, 89);"
                    ><span style="color: rgb(89, 89, 89);"
                      >. The defect report will be taken into consideration and
                      further investigated if it has been emailed within a
                      period of thirty (30) days after discovery.</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1;">
            <br />
          </div>
          <div
            class="MsoNormal"
            data-custom-class="body_text"
            style="line-height: 1.5;"
          >
            <span style="font-size: 15px;"
              ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                ><span style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span style="color: rgb(89, 89, 89);"
                    ><span style="color: rgb(89, 89, 89);"
                      >9.4 &nbsp;If we confirm that the Licensed Application is
                      defective, Ozunu Corp reserves a choice to remedy the
                      situation either by means of solving the defect or
                      substitute delivery.</span
                    ></span
                  ></span
                ></span
              ></span
            >
          </div>
          <div style="line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                ><span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    ><span
                      style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="font-size: 15px;"
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                            >9.5&nbsp;</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >&nbsp;</span
                >In the event of any failure of the Licensed Application to
                conform to any applicable warranty, You may notify the Services
                Store Operator, and Your Licensed Application purchase price
                will be refunded to You. To the maximum extent permitted by
                applicable law, the Services Store Operator will have no other
                warranty obligation whatsoever with respect to the Licensed
                Application, and any other losses, claims, damages, liabilities,
                expenses, and costs attributable to any negligence to adhere to
                any warranty.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                ><span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    ><span
                      style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                      ><span style="font-size: 15px;"
                        ><span
                          style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                          ><span
                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                            >9.6</span
                          ></span
                        ></span
                      ></span
                    ></span
                  >
                  &nbsp;</span
                >If the user is an entrepreneur, any claim based on faults
                expires after a statutory period of limitation amounting to
                twelve (12) months after the Licensed Application was made
                available to the user. The statutory periods of limitation given
                by law apply for users who are consumers.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            &nbsp;&nbsp;&nbsp;
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="productclaims"
              style="line-height: 115%;"
            >
              <a name="_wj13r09u8u3u" id="_wj13r09u8u3u"></a
              ><strong
                ><span
                  id="productclaims"
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                  ><strong
                    ><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                      ><strong
                        ><span style="line-height: 24.5333px; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 24.5333px; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 24.5333px; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                          ><span style="font-size: 19px;"
                                            ><strong
                                              >10. PRODUCT CLAIMS</strong
                                            ></span
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span style="font-size: 15px;">Ozunu Corp</span
              ><span style="font-size: 15px;">&nbsp;</span
              ><span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"
                >and the End-User acknow</span
              ><span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >ledge that Ozunu Corp, and not the Services, is responsible for
                addressing any claims of the End-User or any third party
                relating to the Licensed Application or the End-User’s
                possession and/or use of that Licensed Application, including,
                but not limited to:</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5; margin-left: 20px;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >(i) product liability claims;</span
              >
            </div>
            &nbsp;&nbsp;&nbsp;
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5; margin-left: 20px;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >(ii) any claim that the Licensed Application fails to conform
                to any applicable legal or regulatory requirement; and</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5; margin-left: 20px;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >(iii) claims arising under consumer protection, privacy, or
                similar legislation<span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  >, including in connection with Your Licensed Application’s
                  use of the HealthKit and HomeKit</span
                >.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="compliance"
              style="line-height: 17.25px;"
            >
              <strong
                ><span
                  style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                  ><strong
                    ><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                      ><strong
                        ><span
                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 24.5333px; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 24.5333px; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 24.5333px; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                              ><span style="font-size: 19px;"
                                                ><strong
                                                  >11. LEGAL COMPLIANCE</strong
                                                ></span
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="font-size: 14.6667px; line-height: 1.5;"
            >
              <span
                style="font-size: 15px; line-height: 16.8667px; color: rgb(89, 89, 89);"
                >You represent and warrant that You are not located in a country
                that is subject to a US Government embargo, or that has been
                designated by the US Government as a "terrorist supporting"
                country; and that You are not listed on any US Government list
                of prohibited or restricted parties.</span
              >
            </div>
          </div>
          <div style="line-height: 1.5;">
            <br />
          </div>
          <div style="line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="contact"
              style="line-height: 115%;"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                  ><strong
                    ><span
                      style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                      ><strong
                        ><span
                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 24.5333px; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 24.5333px; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 24.5333px; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                              ><strong
                                                ><span
                                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                  ><span
                                                    style="font-size: 19px;"
                                                    ><strong
                                                      >12. CONTACT
                                                      INFORMATION</strong
                                                    ></span
                                                  ></span
                                                ></strong
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >For general inquiries, complaints, questions or claims
                concerning the Licensed Application, please contact:</span
              >
            </div>
            &nbsp; &nbsp; &nbsp; &nbsp;
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >Ozunu Corp</span
              >
            </div>
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >7105 Old Katy Rd Apt 4131</span
              >
            </div>
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >Houston, TX 77024</span
              >
            </div>
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span style="font-size: 15px;">United States</span>
            </div>
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >ozunu.org@outlook.com</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="termination"
              style="line-height: 1.5;"
            >
              <strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                  ><strong
                    ><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                      ><strong
                        ><span
                          style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 24.5333px; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 24.5333px; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 24.5333px; font-size: 19px;"
                                              ><strong
                                                ><span
                                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                  ><strong
                                                    ><span
                                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                      ><span
                                                        style="font-size: 19px;"
                                                        ><strong
                                                          >13.
                                                          TERMINATION</strong
                                                        ></span
                                                      ></span
                                                    ></strong
                                                  ></span
                                                ></strong
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left; line-height: 1;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >The license is valid until terminated by Ozunu Corp or by You.
                Your rights under this license will terminate automatically and
                without notice from Ozunu Corp if You fail to adhere to any
                term(s) of this license. Upon License termination, You shall
                stop all use of the Licensed Application, and destroy all
                copies, full or partial, of the Licensed Application.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            &nbsp; &nbsp; &nbsp;&nbsp;
            <div class="MsoNormal" style="line-height: 1.5;">
              <br />
            </div>
          </div>
          <div style="text-align: left;">
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="thirdparty"
              style="line-height: 1.5;"
            >
              <a name="_k3mndam4w6w1" id="_k3mndam4w6w1"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                  ><strong
                    ><span
                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                      ><strong
                        ><span
                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 24.5333px; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 24.5333px; font-size: 19px;"
                                              ><strong
                                                ><span
                                                  style="line-height: 24.5333px; font-size: 19px;"
                                                  ><strong
                                                    ><span
                                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                      ><strong
                                                        ><span
                                                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                          ><span
                                                            style="font-size: 19px;"
                                                            ><strong
                                                              >14. THIRD-PARTY
                                                              TERMS OF
                                                              AGREEMENTS AND
                                                              BENEFICIARY</strong
                                                            ></span
                                                          ></span
                                                        ></strong
                                                      ></span
                                                    ></strong
                                                  ></span
                                                ></strong
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></strong
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size: 15px; line-height: 115%; font-family: Arial; color: rgb(89, 89, 89);"
                >Ozunu Corp represents and warrants that Ozunu Corp will comply
                with applicable third-party terms of agreement when using
                Licensed Application.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >In Accordance with Section 9 of the "Instructions for Minimum
                Terms of Developer's End-User License Agreement," both Apple and
                Google and their subsidiaries shall be third-party beneficiaries
                of this End User License Agreement and — upon Your acceptance of
                the terms and conditions of this License Agreement, both Apple
                and Google will have the right (and will be deemed to have
                accepted the right) to enforce this End User License Agreement
                against You as a third-party beneficiary thereof.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left;">
            <div class="MsoNormal" style="line-height: 1.5;">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="ipr"
              style="line-height: 1.5;"
            >
              <a name="_e2dep1hfgltt" id="_e2dep1hfgltt"></a
              ><strong
                ><span style="line-height: 115%; font-family: Arial;"
                  ><span style="font-size: 19px;"
                    ><strong
                      ><span
                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                        ><strong
                          ><span
                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                            ><strong
                              ><span
                                style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                ><strong
                                  ><span
                                    style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                                    ><strong
                                      ><span
                                        style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                        ><strong
                                          ><span
                                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                            ><strong
                                              ><span
                                                style="line-height: 24.5333px; font-size: 19px;"
                                                ><strong
                                                  ><span
                                                    style="line-height: 24.5333px; font-size: 19px;"
                                                    ><strong
                                                      ><span
                                                        style="line-height: 24.5333px; font-size: 19px;"
                                                        ><strong
                                                          ><span
                                                            style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                            ><strong
                                                              ><span
                                                                style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                                ><span
                                                                  style="font-size: 19px;"
                                                                  ><strong
                                                                    >15.
                                                                    INTELLECTUAL
                                                                    PROPERTY
                                                                    RIGHTS</strong
                                                                  ></span
                                                                ></span
                                                              ></strong
                                                            ></span
                                                          ></strong
                                                        ></span
                                                      ></strong
                                                    ></span
                                                  ></strong
                                                ></span
                                              ></strong
                                            ></span
                                          ></strong
                                        ></span
                                      ></strong
                                    ></span
                                  ></strong
                                ></span
                              ></strong
                            ></span
                          ></strong
                        ></span
                      ></strong
                    ></span
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >Ozunu Corp and the End-User acknowledge that, in the event of
                any third-party claim that the Licensed Application or the
                End-User's possession and use of that Licensed Application
                infringes on the third party's intellectual property rights,
                Ozunu Corp, and not the Services, will be solely responsible for
                the investigation, defense, settlement, and discharge or any
                such intellectual property infringement claims.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left;">
            <div class="MsoNormal" style="line-height: 1.5;">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="law"
              style="line-height: 1.5;"
            >
              <a name="_p6vbf8atcwhs" id="_p6vbf8atcwhs"></a
              ><strong
                ><span style="line-height: 115%; font-family: Arial;"
                  ><span style="font-size: 19px;"
                    ><strong
                      ><span style="line-height: 115%; font-family: Arial;"
                        ><span style="font-size: 19px;"
                          ><strong
                            ><span
                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                              ><strong
                                                ><span
                                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                  ><strong
                                                    ><span
                                                      style="line-height: 24.5333px; font-size: 19px;"
                                                      ><strong
                                                        ><span
                                                          style="line-height: 24.5333px; font-size: 19px;"
                                                          ><strong
                                                            ><span
                                                              style="line-height: 24.5333px; font-size: 19px;"
                                                              ><strong
                                                                ><span
                                                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                                  ><strong
                                                                    ><span
                                                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                                      ><span
                                                                        style="font-size: 19px;"
                                                                        ><strong
                                                                          >16.
                                                                          APPLICABLE
                                                                          LAW</strong
                                                                        ></span
                                                                      ></span
                                                                    ></strong
                                                                  ></span
                                                                ></strong
                                                              ></span
                                                            ></strong
                                                          ></span
                                                        ></strong
                                                      ></span
                                                    ></strong
                                                  ></span
                                                ></strong
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></strong
                          ></span
                        ></span
                      ></strong
                    ></span
                  ></span
                ></strong
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1;">
            <br />
          </div>
          <div style="text-align: left;">
            <div
              class="MsoNormal"
              data-custom-class="body_text"
              style="line-height: 1.5;"
            >
              <span
                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                >This License Agreement is governed by the laws of the State of
                Texas excluding its conflicts of law rules.</span
              >
            </div>
          </div>
          <div style="text-align: left; line-height: 1.5;">
            <br />
          </div>
          <div style="text-align: left;">
            <div class="MsoNormal" style="line-height: 1.5;">
              <br />
            </div>
            <div
              class="MsoNormal"
              data-custom-class="heading_1"
              id="misc"
              style="line-height: 1.5;"
            >
              <a name="_v5i5tjw62cyw" id="_v5i5tjw62cyw"></a
              ><strong
                ><span
                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                  ><strong
                    ><span style="line-height: 115%; font-family: Arial;"
                      ><span style="font-size: 19px;"
                        ><strong
                          ><span style="line-height: 115%; font-family: Arial;"
                            ><span style="font-size: 19px;"
                              ><strong
                                ><span
                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                  ><strong
                                    ><span
                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                      ><strong
                                        ><span
                                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                          ><strong
                                            ><span
                                              style="line-height: 24.5333px; color: black; background-image: initial; background-position: initial; background-size: initial; background-repeat: initial; background-attachment: initial; background-origin: initial; background-clip: initial; font-size: 19px;"
                                              ><strong
                                                ><span
                                                  style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                  ><strong
                                                    ><span
                                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                      ><strong
                                                        ><span
                                                          style="line-height: 24.5333px; font-size: 19px;"
                                                          ><strong
                                                            ><span
                                                              style="line-height: 24.5333px; font-size: 19px;"
                                                              ><strong
                                                                ><span
                                                                  style="line-height: 24.5333px; font-size: 19px;"
                                                                  ><strong
                                                                    ><span
                                                                      style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                                      ><strong
                                                                        ><span
                                                                          style="line-height: 115%; font-family: Arial; font-size: 19px;"
                                                                          ><span
                                                                            style="font-size: 19px;"
                                                                            ><strong
                                                                              >17.
                                                                              MISCELLANEOUS</strong
                                                                            ></span
                                                                          ></span
                                                                        ></strong
                                                                      ></span
                                                                    ></strong
                                                                  ></span
                                                                ></strong
                                                              ></span
                                                            ></strong
                                                          ></span
                                                        ></strong
                                                      ></span
                                                    ></strong
                                                  ></span
                                                ></strong
                                              ></span
                                            ></strong
                                          ></span
                                        ></strong
                                      ></span
                                    ></strong
                                  ></span
                                ></strong
                              ></span
                            ></span
                          ></strong
                        ></span
                      ></span
                    ></strong
                  ></span
                ></strong
              >
            </div>
            <div class="MsoNormal" style="line-height: 1;">
              <br />
            </div>
            <div class="MsoNormal" style="line-height: 1;">
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                  ><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    ><span
                      style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                      ><span
                        style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                        ><span
                          style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                          ><span
                            style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                            ><span style="font-size: 15px;"
                              ><span
                                style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                ><span
                                  style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                  ><span
                                    style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                                    ><span style="font-size: 15px;"
                                      ><span
                                        style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                        ><span
                                          style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                          ><span style="color: rgb(89, 89, 89);"
                                            ><span
                                              style="color: rgb(89, 89, 89);"
                                              ><span
                                                style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                ><span style="font-size: 15px;"
                                                  ><span
                                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                    ><span
                                                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                      ><span
                                                        style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                                                        ><span
                                                          style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                                                          ><span
                                                            style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                                                            ><span
                                                              style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                                                              ><span
                                                                style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                                                                ><span
                                                                  style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                  ><span
                                                                    style="font-size: 15px;"
                                                                    ><span
                                                                      style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                      ><span
                                                                        style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                        >17<span
                                                                          style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                          ><span
                                                                            style="font-size: 15px;"
                                                                            ><span
                                                                              style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                              ><span
                                                                                style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                                                                ><span
                                                                                  style="color: rgb(89, 89, 89);"
                                                                                  >.1</span
                                                                                ></span
                                                                              ></span
                                                                            ></span
                                                                          ></span
                                                                        ></span
                                                                      ></span
                                                                    ></span
                                                                  ></span
                                                                ></span
                                                              ></span
                                                            ></span
                                                          ></span
                                                        ></span
                                                      ></span
                                                    ></span
                                                  ></span
                                                ></span
                                              ></span
                                            ></span
                                          ></span
                                        ></span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >&nbsp;</span
                    >&nbsp;</span
                  >If any of the terms of this agreement should be or become
                  invalid, the validity of the remaining provisions shall not be
                  affected. Invalid terms will be replaced by valid ones
                  formulated in a way that will achieve the primary
                  purpose.</span
                >
              </div>
              &nbsp;&nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp; &nbsp;
              <div
                class="MsoNormal"
                data-custom-class="body_text"
                style="line-height: 1.5;"
              >
                <span
                  style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                  ><span
                    style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                    ><span
                      style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                      ><span
                        style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                        ><span
                          style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                          ><span
                            style="font-size:11.0pt;line-height:115%;font-family:Arial; Calibri;color:#595959;mso-themecolor:text1;mso-themetint:166;"
                            ><span
                              style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                              ><span style="font-size: 15px;"
                                ><span
                                  style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                  ><span
                                    style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                    >17<span
                                      style="font-size: 11pt; line-height: 16.8667px; color: rgb(89, 89, 89);"
                                      ><span style="font-size: 15px;"
                                        ><span
                                          style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                          ><span
                                            style="line-height: 16.8667px; color: rgb(89, 89, 89);"
                                            ><span
                                              style="color: rgb(89, 89, 89);"
                                              >.2</span
                                            ></span
                                          ></span
                                        ></span
                                      ></span
                                    ></span
                                  ></span
                                ></span
                              ></span
                            ></span
                          ></span
                        ></span
                      >&nbsp;</span
                    >&nbsp;</span
                  >Collateral agreements, changes and amendments are only valid
                  if laid down in writing. The preceding clause can only be
                  waived in writing.</span
                >
              </div>
            </div>
          </div>
        </div>
        <div
          style="color: #595959;font-size: 14px;font-family: Arial;padding-top:16px;"
        >
          This EULA was created using Termly's
          <a
            style="color: rgb(48, 48, 241) !important;"
            href="https://termly.io/products/eula-generator/"
            >EULA Generator</a
          >.
        </div>
      </div>
    </ion-content>
  </ion-page>
</template>

<script>
import { IonPage, IonContent } from "@ionic/vue";

export default {
  name: "EULA",
  components: {
    IonPage,
    IonContent,
  },
  methods: {
    scrollToSection(sectionName) {
      const element = document.getElementById(sectionName);
      if (!element) return;
      element.scrollIntoView({
        behavior: "smooth",
      });
    },
  },
};
</script>
<style scoped lang="scss">
#container {
  text-align: center;
  position: absolute;
  left: 0;
  right: 0;
  top: 10%;
}
ul {
  list-style-type: square;
}
ul > li > ul {
  list-style-type: circle;
}
ul > li > ul > li > ul {
  list-style-type: square;
}
ol li {
  font-family: Arial;
}
</style>
